export const home = {
  name: "Home",
  path: "/",
  auth: false,
  menu: true,
}

export const about = {
  name: "About",
  path: "/about",
  auth: false,
  menu: true,
}

export const login = {
  name: "Login",
  path: "/login",
  auth: false,
  menu: false,
}

export const signup = {
  name: "Sign Up",
  path: "/signup",
  auth: false,
  menu: false,
}

export const blog = {
  name: "Blog",
  path: "/blog",
  auth: false,
  menu: true,
}

export const booking = {
  name: "Booking",
  path: "/booking",
  auth: true,
  menu: true,
  nest: {
    current: {
      name: "My Booking",
      path: "current",
      menu: true,
    },
    history: {
      name: "History Booking",
      path: "history",
      menu: true,
    },
    finished: {
      name: "Finished Booking",
      path: "finished",
      menu: true,
    },
    detail: {
      name: "Booking Detail",
      path: ":id",
      menu: false,
    },
  },
}

export default {
  home,
  login,
  signup,
  about,
  blog,
  booking,
}
