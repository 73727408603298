import React, { useState } from "react"
import LimitInput from "./utils/limitInput"
import { setUser, getUser, delUser } from "../localstorage/user"

const Login = ({ defaultUserName = "", defaultPassword = "" }) => {
  const [form, setForm] = useState({
    email: defaultUserName,
    password: defaultPassword,
  })
  const [showPasswd, setShowPasswd] = useState(false)
  const bodyContent = (
    <>
      <div className="field">
        <label className="label">Email</label>
        <div className="control">
          <LimitInput
            limit={20}
            callbackChanged={limitValue => {
              setForm({ ...form, email: limitValue })
            }}
          >
            {(value, handleChange) => (
              <input
                className="input"
                type="email"
                placeholder="e.g Alex Smith"
                value={value}
                onChange={e => handleChange(e.target.value)}
              />
            )}
          </LimitInput>
        </div>
      </div>
      <div className="field">
        <label className="label">Password</label>
        <div className="control has-icons-right">
          <LimitInput
            limit={20}
            callbackChanged={limitValue => {
              setForm({ ...form, password: limitValue })
            }}
          >
            {(value, handleChange) => (
              <input
                className="input"
                type={showPasswd ? "text" : "password"}
                placeholder="e.g. alexsmith@gmail.com"
                value={value}
                onChange={e => handleChange(e.target.value)}
              />
            )}
          </LimitInput>

          <a
            className="icon is-small is-right"
            style={{ pointerEvents: "initial" }}
            onClick={e => {
              setShowPasswd(!showPasswd)
              console.log("showPasswd", !showPasswd)
            }}
          >
            <i className={showPasswd ? "fas fa-eye-slash" : "fas fa-eye"} />
          </a>
        </div>
      </div>
      <div className="columns is-tablet is-centered is-vcentered">
        <div className="column is-3">
          <div className="buttons">
            <a
              className="button is-fullwidth is-light"
              onClick={() => {
                if (getUser()) {
                  delUser()
                } else {
                  setUser({ name: "test", password: "test" })
                }
              }}
            >
              {getUser() ? "Log Out" : "Log In"}
            </a>
          </div>
        </div>
        <div className="column is-hidden-tablet is-1 has-text-centered is-size-7 is-paddingless has-text-weight-light">
          OR
        </div>
        <div className="column is-3">
          <div className="buttons">
            <a className="button is-fullwidth is-primary">Sign up</a>
          </div>
        </div>
      </div>
    </>
  )
  return bodyContent
}

export default Login
