import React, { useContext, useEffect } from "react"
import Layout from "../components/layout"
import { Router } from "@reach/router"
import Login from "../components/login"
import NotFound from "../components/notfound"
import * as sitemap from "../sitemap"
import Authen from "../components/utils/authen"
import Blog from "../components/app-pages/blog"
import appContext from "../context/app-context"

const Booking = props => (
  <Authen authenPath="/login">
    <div>Booking Mange {props.children}</div>
  </Authen>
)
const BookingNest = () => <div>Booking Nest</div>
const BookingByStatus = props => <div>Booking {props.status.toUpperCase()}</div>
const BookingDetail = props => <div>Booking ID: {props.id}</div>
const SignUp = () => <div>SignUp Page</div>

const App = props => {
  const context = useContext(appContext)
  useEffect(() => {
    console.log("testLocationChange", props.location.pathname)
    context.updateLastUrlLoading(props.location.pathname)
    return () => {
      context.updateLastUrlLoading(null)
    }
  }, [props.location])
  return (
    <Layout>
      <Router>
        <Login path={sitemap.login.path} />
        <SignUp path={sitemap.signup.path} />
        <Blog path={sitemap.blog.path} />
        <Booking path={sitemap.booking.path}>
          <BookingNest path="nest" />
          <BookingByStatus status="current" path={sitemap.booking.nest.current.path} />
          <BookingByStatus status="history" path={sitemap.booking.nest.history.path} />
          <BookingByStatus status="finished" path={sitemap.booking.nest.finished.path} />
          <BookingDetail path={sitemap.booking.nest.detail.path} />
        </Booking>
        <NotFound default />
      </Router>
    </Layout>
  )
}

export default App
