import React, { useEffect } from "react"
import { navigate } from "gatsby"
import * as user from "../../localstorage/user"

const Authen = ({ children, authenPath, continuePage }) => {
  useEffect(() => {
    if (user.getUser() !== -1 && !user.getUser()) {
      navigate(authenPath, { state: { continuePage: continuePage } })
    }
  }, [])
  const content = user.getUser() !== -1 ? user.getUser() ? children : <div /> : <div />
  return content
}

export default Authen
