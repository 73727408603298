import { isClientRender } from "../helper/common"

const key = "user"

export const setUser = data => {
  localStorage.setItem(key, JSON.stringify(data))
}
export const getUser = () => {
  return isClientRender() ? localStorage.getItem(key) : -1
}
export const delUser = () => {
  localStorage.removeItem(key)
}
