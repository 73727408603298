import React, { useState } from "react"
import PropTypes from "prop-types"

const getLimitValue = (value, limit) => {
  let limitValue = value
  if (value && value.length > limit) {
    limitValue = value.slice(0, limit)
  }
  return limitValue
}

const LimitInput = ({ children, defaultValue, limit = 100, callbackChanged }) => {
  const [limitValue, setLimitValue] = useState(getLimitValue(defaultValue, limit))
  const handleChange = value => {
    const resultValue = getLimitValue(value, limit)
    setLimitValue(resultValue)
    callbackChanged(resultValue)
  }
  const content = <>{children(limitValue, handleChange)}</>
  return content
}

LimitInput.propTypes = {
  children: PropTypes.func,
  defaultValue: PropTypes.string,
  limit: PropTypes.number,
}

LimitInput.defaultProps = {
  children: (limitValue, handleChange) => <div />,
  callbackChanged: v => {},
}

export default LimitInput
