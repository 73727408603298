import React from "react"
import marked from "marked"
import Rest, { GET } from "../utils/rest"

const Blog = () => {
  return (
    <Rest url="http://localhost:1337/restaurants/1" method={GET} showPageLoading>
      {content => {
        if (content.data) {
          return (
            <div
              id="content-render"
              dangerouslySetInnerHTML={{ __html: marked(content.error) }}
            />
          )
        } else if (content.error) {
          return <div id="content-render">{content.error}</div>
        }
        return <div id="content-render">{content.loading}</div>
      }}
    </Rest>
  )
}

export default Blog
