import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import appContext from "../../context/app-context"

export const GET = "GET"
export const POST = "POST"
export const PUT = "PUT"
export const DELETE = "DELETE"

const Rest = ({
  children = ({ data, error, loading = false }) => {},
  method,
  url,
  body,
  showPageLoading = false,
}) => {
  const [content, setContent] = useState({
    data: null,
    error: null,
    loading: "Loading...",
  })
  const context = useContext(appContext)
  useEffect(() => {
    async function fetchData() {
      console.log("testLocationChange", "fetch", {
        last: context.lastUrlLoading,
        current: window.location.pathname,
      })
      if (showPageLoading) {
        context.fetchPageBegin()
      }
      let axiosPromise = null
      switch (method) {
        case PUT:
          axiosPromise = axios.put(url, body)
          break
        case POST:
          axiosPromise = axios.post(url, body)
          break
        case DELETE:
          axiosPromise = axios.delete(url)
          break
        default:
          axiosPromise = axios.get(url)
      }
      try {
        const response = await axiosPromise
        console.log("testLocationChange", "finish", response.data)
        setContent({
          data: response.data,
          error: null,
          loading: false,
        })
      } catch (error) {
        console.log("testLocationChange", "error", error)
        setContent({
          data: null,
          error: error.message,
          loading: false,
        })
      } finally {
        console.log("testLocationChange", "end")
        if (showPageLoading) {
          context.fetchPageEnd()
        }
      }
    }
    fetchData()
  }, [])

  console.log("testLocationChange", "content", content)
  return <div>{children(content)}</div>
}

export default Rest
